<template>
    <div>
        <div class="flex items-center justify-between bg-white border rounded-t-sm px-4 shadow-lg">
            <div>
                <stepper>
                    <li><button class="active">DRAFT</button></li>
                    <li><button>IN PROGRESS</button></li>
                    <li><button>VALIDATED</button></li>
                </stepper>
            </div>
            <div>
                <!-- <button @click="onConfirm" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100 relative" :disabled="isDisabled">
                    <div v-if="isDisabled" class="absolute bg-teal-400 bg-opacity-70 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    CONFIRM
                </button> -->
            </div>
        </div>
        <div class="m-5 bg-white rounded-t-md">
            <div class="flex justify-between items-center border">
                <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                    <div class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-red-500 hover:border-gray-200 hover:text-red-600">INFO</div>
                </div>
            </div>
            <div class="border border-t-0 border-b-0 p-5">
                <div class="grid grid-cols-9 divide-x">
                    <div class="col-span-5 pr-5">
                        <div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">New</div>
                                <div class="text-gray-400 text-xs">STOCK OPNAME #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">--</div>
                                <div class="text-gray-400 text-xs">DATE CREATED</div>
                            </div>
                        </div>
                        <div>
                            <label for="">WAREHOUSE <span class="text-red-500">*</span></label>
                            <div class="flex flex-col mt-2" :class="{'err': err.warehouseId}">
                                <select-2 v-model="warehouseId" :options="warehouses" class="form-controll" :placeholder="'Select warehouse'">
                                    <option value=""></option>
                                </select-2>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-4 pl-5">
                        <div class="grid grid-cols-2 gap-5 mb-6">
                            <div class="col-span-1">
                                <div>
                                    <label for="">VALIDATE</label>
                                    <div>--</div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div>
                                    <label for="">USER</label>
                                    <div>{{user.name}}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="">REMARKS</label>
                            <div class="mt-2">
                                <input v-model="form.remarks" type="text" class="form-control" placeholder="Remarks">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <add-items :isDisabled="!form.warehouseId" v-model="form.items" :warehouseId="form.warehouseId"></add-items>
        </div>
         <!-- ALERT -->
        <div v-show="invalids.length > 0" class="fixed bg-red-200 z-50 animated fadeInDown faster top-5 w-72 right-5 text-black p-2 rounded-sm shadow-lg">
            <div class="font-medium flex justify-between items-center">
                <div>Invalid Fields:</div>
                <a @click.prevent="closeAlert" href="#" class="hover:text-red-600"><i class="icon-cross3"></i></a>
            </div>
            <div class="pl-5">
                <ul class="list-disc">
                    <li v-for="(invalid, i) in invalids" :key="i">{{invalid.text}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import stepper from '../../../components/stepper.vue'
import Select2 from '../../../components/Select2.vue'
import addItems from '../../../components/additemsStockOpname.vue'
import {mapGetters} from 'vuex'
import axios from 'axios'
import BreadcrumbsManager from '../../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../../event-bus'
import Swal from 'sweetalert2/dist/sweetalert2'
export default {
    components: {stepper, Select2, addItems},
    mixins: [BreadcrumbsManager],
    data () {
        return {
            warehouses: [],
            warehouseId: '',
            form: {
                warehouseId: '',
                remarks: '',
                items: [],
                userId: ''
            },
            err: {
                warehouseId: ''
            },
            invalids: [],
            invalidTimeout: ''
        }
    },
    mounted () {
        this.setButtonText('SAVE')
        this.setDiscard(true)
        this.setDiscardText('DISCARD')
        this.pushBreadcrumb({
            text: 'New'
        })
        this.getData()
        EventBus.$on('onClick', this.onSubmit)
        EventBus.$on('onCancel', this.onDiscard)
    },
    destroyed () {
        EventBus.$off('onClick', this.onSubmit)
        EventBus.$off('onCancel', this.onDiscard)
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    methods: {
        getData () {
            axios.get('/stockopname/create')
            .then(res => {
                this.warehouses = res.data
            })
        },
        onSubmit () {
            this.form.userId = this.user._id
            if(this.form.items.length == 0) {
                this.invalids.push({text: 'Items'})
            }
            if(!this.form.warehouseId) {
                this.err.warehouseId = true
                this.invalids.push({text: 'Warehouse'})
            }
            for(let i=0; i < this.form.items.length; i++) {
                this.form.items[i].edited = false
                if(!this.form.items[i].productId) {
                    this.invalids.push({text: 'Items'})
                    return
                }
            }
            if(this.form.warehouseId && this.form.items.length > 0) {
                this.setDisabled(true)
                axios.post('/stockopname/insert', this.form)
                .then((res) => {
                    this.setDisabled(false)
                    this.$router.push('/stock-opname/detail/'+res.data._id)
                })
            }
        },
        onDiscard () {
            this.$router.push('/stock-opname')
        },
        closeAlert () {
            this.invalids = []
            clearTimeout(this.invalidTimeout)
        },
    },
    watch: {
        warehouseId (val) {
            if(val && this.form.items.length > 0 && this.form.warehouseId != this.warehouseId) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, cancel!',
                    confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                    preConfirm: function () {
                        return 
                    },
                    allowOutsideClick: false
                })
                .then(res => {
                    if(res.value) {
                        this.form.warehouseId = val
                        this.form.items = []
                    } else {
                        this.warehouseId = this.form.warehouseId
                    }
                })
            }
            if(val && this.form.items.length == 0) {
                this.err.warehouseId = ''
                this.form.items = []
                this.form.warehouseId = val
            }
        },
        invalids () {
            clearTimeout(this.invalidTimeout)
            this.invalidTimeout = setTimeout(this.closeAlert, 3500)
        }
    },
    beforeRouteLeave (to, from , next) {
        this.popBreadcrumb()
        next()
    }
}
</script>